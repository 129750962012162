<template>
    <input ref="input" :type="type" :class="inputClass" :disabled="disabled" class="form-control" :value="numberStr" v-on:input="onInput" v-on:blur="onBlur" v-on:focus="onFocus" v-on:keypress.enter="blur"/>
</template>

<script>

export default {
    props:{
        value:Number,
        disabled:{
            type:Boolean,
            default:null
        },
        inputClass:{
            type:String,
            default:null
        }
    },
    data(){
        return{
            type:'text',
            numberStr:""
        }
    },
    watch:{
        value(newV,oldV){
            if(!isNaN(oldV) && !isNaN(newV) && this.type == 'text'){
                this.numberStr = this.toNumberStr(this.value)
            }
        },
    },
    mounted(){
        this.numberStr = this.toNumberStr(this.value)
    },
    methods:{
        toNumberStr(n){
            let nn = n
            if(n == null || n == "" || n == 'NaN' || isNaN(n)){ nn = 0 }
            return new Intl.NumberFormat('tr-TR', {
                currency: 'TRY',
                minimumFractionDigits: 2,
            }).format(nn)
        },
        onInput($e){
            this.$emit('update-value',parseFloat($e.target.value?.replace(',','.'))||null)
        },
        onBlur(){
            this.type = 'text'
            this.numberStr = this.toNumberStr(this.value)
        },
        onFocus(){
            this.numberStr = this.value
            this.type = 'number' 
        },
        blur(){
            this.$refs.input.blur()
        }
    },
}
</script>

<style>

</style>

