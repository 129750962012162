<template>
    <span class="font-weight-bolder" v-b-tooltip.noninteractive.hover.bottom :title="text">{{c_short}}</span>
</template>

<script>
import {VBTooltip} from 'bootstrap-vue'
export default {
    props:{
        text:{
            type:String,
            default:""
        },
    },
    directives:{
        "b-tooltip":VBTooltip
    },
    computed:{
        c_short(){
            return this.text.split(" ").map(t=> t?.trim()?.substring(0,1)?.toLocaleUpperCase()).join("") 
        }
    }
}
</script>

<style>

</style>