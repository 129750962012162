export default [
    "ai",
    "android",
    "apk",
    "css",
    "disc",
    "doc",
    "excel",
    "font",
    "iso",
    "javascript",
    "jpg",
    "jpeg",
    "png",
    "js",
    "mail",
    "mp3",
    "mp4",
    "music",
    "pdf",
    "php",
    "play",
    "powerpoint",
    "ppt",
    "psd",
    "record",
    "sql",
    "svg",
    "text",
    "ttf",
    "txt",
    "vcf",
    "vector",
    "video",
    "word",
    "xls",
    "xlsx",
    "zip"
]