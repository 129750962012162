<template>
    <div class="file-uploader alert alert-dark mb-0 rounded d-flex align-items-center justify-content-between p-1" :class="disabled ? 'disabled':null">
        <input class="d-none" type="file" ref="fileInput" :multiple="multiple" :accept="c_acceptedFiles ? c_acceptedFiles : null" v-on:change="changeFile"/>
        <div v-if="!c_fileSelected" class="d-flex align-items-center">
            <span class="material-icons-round upload-icon text-primary mr-1">file_upload</span>
            <span class="text-secondary font-weight-bolder upload-text text-nowrap">Yüklemek istediğiniz dosyayı seçiniz.</span>
        </div>
        <div class="d-flex align-items-center file-name-wrapper">
            <div v-if="!multiple && c_fileSelected && !c_image" class="ext-container text-center mr-1">
                <img :src="c_extIcon" />
            </div>
            <div v-if="!multiple && c_fileSelected && c_image">
                <img v-image-error class="preview-img mr-1"  :src="c_image" />
            </div>
            <div v-if="!multiple" class="font-weight-bolder text-truncate">{{c_name}}</div>
        </div>
        <div v-if="multiple" class="w-100">
            <div class="mb-50" v-if="c_value.length">Toplam <strong>{{c_value.length}}</strong> Adet</div>
            <ul class="list-group" v-if="c_value.length">
                <li class="list-group-item" v-for="(file,index) in c_value" :key="index">
                    <div class="d-flex align-items-center justify-content-between">
                        <span>{{file.name}}</span>
                        <button class="btn btn-sm btn-icon btn-outline-danger" v-on:click="$delete(c_value,index)">
                            <feather-icon icon="TrashIcon"></feather-icon>
                        </button>
                    </div>
                </li>
                <li class="list-group-item bg-secondary text-white d-flex align-items-center c-pointer" v-on:click="$refs.fileInput.click()">
                    <span class="material-icons-outlined mr-50">add_circle</span>
                    Dosya Ekle
                </li>
            </ul>
        </div>
        <div class="text-nowrap" v-if="!(multiple && c_value.length > 0)"> 
            <button v-if="c_downloadable" class="btn btn-sm btn-success" v-on:click="download">İndir</button>
            <button class="btn btn-secondary btn-sm ml-1" v-on:click="$refs.fileInput.click()">
                {{c_fileSelected ? 'Değiştir' : 'Dosya Seç'}}
            </button>
            <button v-if="!multiple && c_fileSelected" class="btn btn-danger btn-sm ml-1"  v-on:click="clear">Sil</button>
        </div>
    </div>
</template>

<script>
import iconList from './icon-list.js'
import Additional from '@/utilities/additional'
export default {
    props:{
        value:[Object,File],
        accept:{
            type:Array,
            default:()=>[]
        },
        multiple:{
            type:Boolean,
            default:false
        },
        valueParameter:String,
        disabled:Boolean,
    },
    methods:{
        changeFile(){
            if(!this.multiple){
                const file = this.$refs.fileInput.files[0]
                if(file){
                    const ext = file.name.split('.').pop()
                    if(this.accept.includes(ext)){
                        this.c_value = file  
                    }
                }else  this.c_value = null
                this.$refs.fileInput.value = null
            }else{
                const files = this.$refs.fileInput.files
                if(files.length){
                    Array.from(files).forEach(file=>{
                        const ext = file.name.split('.').pop()
                        if(this.accept.includes(ext)){
                            this.c_value.push(file)
                        }
                    })
                }
                this.$refs.fileInput.value = null
            }
        },
        clear(){
            this.c_value = null
        },
        async download(){
            let fileBlob = await Additional.GetBlobFile(this.c_value)
            Additional.DownloadFile(fileBlob.data,this.c_name)
        }
    },
    computed:{
        c_acceptedFiles(){
            return this.accept.map(a=> ['.',a].join('')).join(',')
        },
        c_fileSelected(){
            if(this.multiple){
                return this.c_value && this.c_value.length > 0 ? true : false
            }else return this.c_value ? true : false
        },
        c_downloadable(){
            if(typeof this.c_value === 'string'){
                return this.c_value
            }
        },
        c_ext(){
            let ext = null
            if(this.c_value != null){
                if(typeof this.c_value === 'object'){ ext = this.c_value?.name.split('.').pop() }
                else{ ext = this.c_value.split('.').pop() }
            }
            return ext
        },
        c_extIcon(){
            if(iconList.includes(this.c_ext) && this.c_ext != null){
                return require(`@/assets/images/file-types/${this.c_ext}.svg`)
            }else{
                return require(`@/assets/images/file-types/file.svg`)
            }
        },
        c_image(){
            if(typeof this.c_value === "object" && this.c_value !== null){
                let file = this.c_value
                const ext = file.name.split('.').pop()
                if(ext == 'png' || ext == 'jpg' || ext == 'jpeg'){
                    return URL.createObjectURL(file)
                }else return null
            }else if(typeof this.c_value === "string"){
                let file = this.c_value
                let ext = file.split('.').pop()
                if(ext == 'png' || ext == 'jpg' || ext == 'jpeg'){
                    return file
                }
            }else return null
        },
        c_name(){
            if(typeof this.c_value === "object" && this.c_value !== null){
                return this.c_value?.name || null
            }else if(typeof this.c_value === "string"){
                return this.c_value.slice(this.c_value.indexOf('_') + 1)
            }else return null
        },
        c_value:{
            get(){
                if(this.valueParameter){
                    return this.value[this.valueParameter]
                }else{
                    return this.value
                }
            },
            set(value){
                if(this.valueParameter){
                    this.$set(this.value,this.valueParameter,value)
                }else{
                    this.$emit("change",value)
                }
            }
        }
    }
}
</script>

<style>
    .file-uploader.disabled{
        opacity: 0.5;
        pointer-events: none;
    }

    .file-uploader .preview-img{
        height: 2.2rem;
        width: auto;
    }

    .file-uploader .upload-icon{
        height: 2.2rem;
        width: 2.2rem;
        min-width: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
    }

    .file-uploader  .upload-text{
        font-size: 0.85rem;
    }
   
    .file-uploader .ext-container img{
        height: 2.2rem;
    }

    .file-uploader .file-name-wrapper{
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
       
    }
</style>