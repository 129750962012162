import axios from 'axios'

export const VehicleTypeApi = {
    async Table(data){
        const response = await axios.post('/api/vehicletype/table',data)
        return response
    },
    async CreateOrUpdate(update,data){
        const response = await axios.post(update ? '/api/vehicletype/update' : '/api/vehicletype/create' , data)
        return response
    },
    async Delete(data){
        const response = await axios.post(`/api/vehicletype/delete/${data}`)
        return response
    },
    async List(){
        const response = await axios.get('api/vehicletype/list')
        return response
    }
    
}