<template>
    <b-card :class="['border mb-0',warpperClass]" no-body>
        <b-card-body :class="['d-flex justify-content-between align-items-center',bodyClass]">
            <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">{{value}}</h2>
                <span>{{text}}</span> <template v-if="shortText">(<short-text :text="shortText"></short-text>)</template>
            </div>
            <b-avatar :variant="['light',variant].join('-')" size="45">
                <span class="material-icons-round">{{icon}}</span>
            </b-avatar>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BAvatar,BCardBody } from "bootstrap-vue";
import ShortText from "@/components/ShortText";
export default {
    components:{
        BCard,
        BAvatar,
        BCardBody,
        ShortText
    },
    props:{
        value:[String,Number],
        text:String,
        icon:String,
        shortText:String,
        warpperClass:String,
        bodyClass:String,
        variant:{
            type:String,
            default:"primary"
        }
    },
}
</script>